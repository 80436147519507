
import { Options, Vue } from 'vue-class-component';
import { Store, useStore } from 'vuex';
import { saveAqQa } from '@/api/index';
import GrayHeader from '@/components/grayHeader.vue';
import Submenu from '@/components/faq/submenu.vue';
import { ElForm, ElFormItem, ElInput, ElUpload, ElSelect, ElOption, ElButton, ElMessage } from 'element-plus';
import SigupDialog from '../components/home/sigupDialog.vue';

@Options({
    components: {
        GrayHeader,
        Submenu,
        SigupDialog,
        ElForm,
        ElFormItem,
        ElInput,
        ElUpload,
        ElSelect,
        ElOption,
        ElButton,
        ElMessage,
    },
})
export default class Question extends Vue {
    private store: Store<any> = useStore();
    private tabs = ['我要提问'];
    private quesForm = { title: '', question: '', theme: '' };
    private rules = {
        title: [{ required: true, message: '问题标题不能为空', trigger: ['blur', 'change'] }],
        theme: [{ required: true, message: '问题类型不能为空', trigger: 'change' }],
    };
    private categories: Array<String> = ['大赛规则相关', '报名相关', '队伍相关', '赛题相关', '平台使用相关', '提交相关', '成绩相关', '其它'];

    get hasLogin(): Boolean {
        return this.store.state.loginStatus.haslogin;
    }
    get isDisabled(): Boolean {
        return !(this.quesForm.title !== '' && this.quesForm.theme !== '');
    }
    private quesTips = ['一个清晰、简洁的标题能够让他人快速明确你的问题', '问题描述非必填，但适当的描述有助于让他人理解你的问题'];

    async submitQues() {
        const data = {
            Theme: this.quesForm.theme,
            Title: this.quesForm.title,
            Question: this.quesForm.question,
        };
        const res = await saveAqQa(data);
        if (!res) return;
        const { Code, Data } = res;
        if (!Code && Data) {
            this.$router.push('./faq');
            ElMessage({
                message: '提交问题成功',
                type: 'success',
                customClass: 'alcontest-message-el',
            });
            return;
        }
        ElMessage({
            message: '提交问题失败',
            type: 'error',
            // customClass: 'alcontest-message-el',
        });
    }
}
